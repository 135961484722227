<template>
  <v-app>
    <v-main>
      <v-container class="fill-height backgound" fluid>
        <v-row>
          <v-col class="align-center d-flex flex-column">
            <div class="logo-sistema">
              <v-img
                class="image-background"
                :src="require('@/assets/campanha-e-comissao.svg')"
              >
              </v-img>
            </div>
            <v-card width="350" class="card-login align-center">
              <v-card-title class="title-login mb-8">
                <div>
                  <v-img :src="require('@/assets/logo-grupo-cometa.svg')">
                  </v-img>
                </div>
              </v-card-title>
              <v-card-text>
                <v-form ref="form">
                  <v-text-field
                    class="mb-2"
                    autocomplete="off"
                    v-model="email"
                    :rules="[rules.required]"
                    label="E-mail"
                    suffix="@viacometa.com.br"
                    outlined
                    @keyup.enter="login"
                  ></v-text-field>
                  <v-text-field
                    class="mb-3"
                    v-model="password"
                    :rules="[rules.required, rules.password]"
                    label="Senha"
                    type="password"
                    outlined
                    @keyup.enter="login"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="align-center d-flex flex-column">
                <v-btn
                  big
                  block
                  color="#0f2a49"
                  class="mb-2 white--text"
                  @click="login"
                  >Entrar</v-btn
                >
                <v-btn
                  big
                  color="#0f2a49"
                  block
                  outlined
                  class="mb-4 ml-0 primary--text"
                  :to="{ name: 'cadastro-usuario' }"
                  >Cadastrar</v-btn
                >
                <p>
                  Esqueceu senha?
                  <a @click="goToResetPassword()">
                    Clique para redefinir
                  </a>
                </p>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import rules from "@/mixins/inputRules";
import rolesDashboard from "@/utils/rolesDashboard";

export default {
  name: "Login",

  mixins: [rules],

  data() {
    return {
      email: "",
      password: "",
      rules: {
        password: v => !!v || "O campo senha é obrigatório"
      }
    };
  },

  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch("tryLogin", {
            email: `${this.email}@viacometa.com.br`,
            password: this.password
          });
          this.goToHome();
        } catch (error) {
          let findAt = this.email.split("");

          if (findAt.includes("@"))
            return this.$notify({
              type: "error",
              text: "Usuário ou senha inválidos"
            });

          console.error("erro login", error);
          this.$notify({
            type: "error",
            text: error.response.data.message
          });
        }
      }
    },

    goToHome() {
      this.$router.push(rolesDashboard.path);
    },
    //teste
    goToResetPassword() {
      this.$router.push("/cadastro/solicita-redefinicao-senha");
    }
  }
};
</script>

<style scoped>
.backgound {
  background-image: linear-gradient(to bottom, #0f2a49 35%, #ffffff 35%);
  /* background-image: linear-gradient(#0f2a49, #acacac); */
}

.logo-sistema {
  position: absolute;
  top: 10%;
}

.card-login {
  position: absolute;
  top: 28%;
}

.title-login {
  color: #0f2a49;
  display: flex;
  justify-content: center;
}

@media (max-width: 575.98px) {
  .image-background {
    width: 350px;
  }
}
</style>
